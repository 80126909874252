body {
  background: rgb(247, 247, 247);
  font-family: "MyriadPro-Regular", Myriad, "Liberation Sans", "Nimbus Sans L",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
}
.icon-Nav {
  background-color: white;
  border-color: #080808;
}
.icon-arrow {
  float: right;
}
.headerPersonText {
  font-family: "MyriadPro-Semibold", Myriad, "Liberation Sans", "Nimbus Sans L",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #ffa100;
  letter-spacing: 0;
}
.headerPersonText:hover {
  color: #ffa100;
  text-decoration: none;
}
.icon-text {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  margin-left: 3%;
  background: none;
  border: none;
}
.btnBack {
  background-color: #032336 !important;
}
.icon-text-selected {
  color: #ffa100 !important;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 3%;
  background: none;
  border: none;
}
#report_download:focus {
  outline: none !important;
}
.subTitleText {
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
}
.navMargin {
  margin-top: 20%;
}

.ExcelButton {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  margin-left: 9px;
}

.container {
  width: 100%;
}

/*Slide Left When Navigation bar is open */
.margin-left-240 {
  margin-left: 240px;
}

.mainContent {
  padding: 0px 10px;
  margin-bottom: 4%;
}

.sidebar {
  height: 100%;
  width: 283px;
  position: fixed;
  top: 2rem;
  left: 0;
  border: none;
  border-radius: 0;
  border-top: 1px rgba(0, 0, 0, 0.5) solid;
  background-color: #002b45;
  overflow-x: hidden;
  padding: 0;
  padding-bottom: 40px;
  list-style-type: none;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-bottom: 75px;
  z-index: 5;
  transition: 0.2s linear;
}
.sidebar > li {
  padding: 10px 20px;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.sidebar > li > a {
  width: 225px;
  border-bottom: 1px rgba(0, 0, 0, 0.3) solid;
}

.side-nav li a:hover,
.side-nav li a:focus {
  outline: none;
  background-color: #032336 !important;
}

.sidenavIcon {
  width: 26px;
  height: 24px;
}

.ordersidenavIcon {
  width: 26px;
  height: 24px;
  margin-left: 2px;
}
.form-control:focus {
  box-shadow: none;
}

.card-img-top {
  width: auto;
  height: 40px;
}
a:hover {
  text-decoration: none;
}

#personName > .btn {
  outline: 0;
  box-shadow: none;
}

.dropdown1 {
  background: url("../images/icon/dropdownArrow.svg") no-repeat right !important;
}
#personName > {
  outline: 0;
  box-shadow: none;
}
#personName > .dropdown-toggle::after {
  display: none;
}
#home_card > .card {
  width: 223px !important;
  height: 125px !important;
  max-width: 223px;
  min-width: 204px !important;
  margin-bottom: 30px !important;
  margin-right: 35px;
}
#home_card > .card > .card-body > p {
  font-size: 13px;
  color: #0083a9;
  letter-spacing: 0;
}

#plantLocation {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  width: 164px;
  padding: 5px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 25px;
  background: url("../images/icon/dropdownArrow.svg") no-repeat right #ddd;
  /* font-family: "MyriadPro-Regular", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  -webkit-appearance: none;
  background-position-x: 150px;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0;
  border: none;
  padding: 0;
  background-color: #f7f7f7;
}
.textPlanLoc {
  font-size: 15px;
  color: #838383;
  letter-spacing: 0;
}
/* .footer {
   position: absolute;
   bottom: 0;
   width: 100%;
 
 } */
.footerLinkText {
  font-size: 15px;
  color: #4f85c4;
  letter-spacing: 0;
  padding-left: 0;
}

.footerLinkText:focus {
  outline: none;
}

.srbFooter {
  padding: 15px 63px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0; /* 283px to 0px after removing navbar  */
  background: rgb(247, 247, 247);
  color: rgb(153, 153, 153);
  font-size: 15px;
  line-height: 18px;
  z-index: 1;
  padding-left: 21px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.signOut {
  min-width: 134.72px;
  padding: 0;
  left: 30px;
}

/* .successbtn{
     height: 36px;
     width: 55px;
   }
 
   .dangerbtn{
     height: 36px;
     width: 55px;
   } */

.dropstyle {
  /* wordWrap: "break-word"
     width: 246px; */
  margin-right: 19px;
}

.Notify {
  font-size: 13px;
  height: 200px;
  overflow-y: scroll;
}
.Notify::-webkit-scrollbar {
  width: 5px;
  height: 200px;
}
.Notify::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.Notify::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.time {
  width: 261px;
  font-size: 12px;
  color: #353535;
  white-space: pre-wrap;
}

.confirmOkButton {
  margin: auto !important;
  width: 90px !important;
  margin-top: 30px !important;
}
.btn-ok {
  background-color: #4f85c4;
  border-color: #4f85c4;
  width: 80px !important;
}
.Dropdown-control {
  border: unset !important;
  border-radius: unset !important;
  padding: 2px !important;
  padding-right: unset !important;
  background-color: transparent !important;
  line-height: 1.5 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  font-size: 15px !important;
}
.Dropdown-arrow {
  right: 6px !important;
}
.Dropdown-control:hover {
  box-shadow: unset !important;
}
.dropDownMenuHeader > .Dropdown-option {
  padding: unset !important;
  /* color: black; */
  background-color: white;
  color: #4f85c4;
  font-weight: 600;
  font-size: 15px;
  padding-left: 2px !important;
}
.Dropdown-option {
  padding: unset !important;
  color: black;
  font-size: 15px;
  padding-left: 2px !important;
}

.plantlocationDropdown {
  min-width: 130px;
}

.Dropdown-option.is-selected {
  /* background-color: #007bff;
  color: white */
  background-color: white !important;
  color: unset !important;
}
.Dropdown-option.is-selected:hover {
  background-color: #007bff !important;
  color: white !important;
}
.dropDownMenuHeader > .Dropdown-option:hover {
  background-color: #007bff !important;
  color: white !important;
  font-weight: 600;
}
.Dropdown-option:hover {
  background-color: #007bff !important;
  color: white !important;
}
.Dropdown-menu {
  margin-top: 0px !important;
  /* border: 0.5px solid #007bff !important; */
}

.dropdownPlaceholderHeader {
  color: #ffa100;
  min-width: 60px;
}

.dropDownMenuHeader {
  margin-top: 10px !important;
  display: block !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.25rem !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: #212529 !important;
  font-size: 1rem !important;
  line-height: 36px;
  width: 111px;
  right: 0px;
  /* width: 4em !important;
   right: -20px !important; */
}
.controlClassHeader:hover {
  cursor: pointer !important;
}
.restime {
  display: flex;
  justify-content: space-between;
}

.privacy-card-body {
  overflow-y: scroll;
  height: 351px !important;
  padding: 23px !important;
}

.privacyScrollbar::-webkit-scrollbar {
  width: 7px;
  height: 65px;
}
.privacyScrollbar::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.privacyScrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  width: 7px;
  height: 65px;
}
.dimen {
  height: 303px;
  width: 450px;
}

.buttonTransparent {
  border: none;
  background: none;
}

.notificationSpinner {
  margin: auto;
  margin-top: 80px;
}

.dashboardcontainer {
  padding-left: 9%;
  padding-right: calc(9% - 35px);
}
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #f58b02;
  margin-right: 9px;
}

.dotText {
  font-size: 14px;
  color: #484848;
}

.cardDotHolder {
  height: 26px;
  margin-left: 200px;
}

.viewOnlyText {
  font-size: 12px;
  color: #838383;
}

p {
  margin: 0px;
}

.nav-tabs .nav-link {
  border: none !important;
  color: black !important;
  font-size: 15px;
  letter-spacing: 0px;
}

.nav-tabs .nav-item {
  margin-left: 16px;
  margin-bottom: unset !important;
}

.nav-tabs .nav-link.active {
  color: #0083a9 !important;
  border-bottom: 2px solid !important;
  font-size: 15px;
  letter-spacing: 0px;
}

.nav-tabs {
  border-bottom: unset;
}

.volinccard > .card-body {
  padding: 0px;
}

.displayNone {
  display: none;
}

.textarea:focus,
input:focus {
  outline: none;
}

.input {
  width: 60px;
  border: none !important;
  border-bottom: 0.5px solid #8e8e8e !important;
  outline: none;
  background-color: transparent;
  text-align: end;
  padding-right: 8px;
}
.input:disabled {
  background-color: transparent;
}
.input:focus-within {
  background-color: white !important;
}
.input-borderNone {
  border-bottom: 0px !important;
  text-align: right;
}

.pencil-edit {
  cursor: pointer;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="time"],
select.form-control {
  border: none;
  border-bottom: none;
  box-shadow: none;
  border-radius: none !important;
  outline: none;
}

.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  border-bottom: 0;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="time"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.searchButton {
  background-color: #4f85c4;
  border-color: #4f85c4;
  border-radius: 5px;
  width: 9%;
  margin-left: 15px;
  border-style: solid;
  font-family: "Calibri";
}

.tooltip-main {
  width: 15px;
  height: auto;
  padding-bottom: 10px;
  border-radius: 50%;
  font-weight: 700;
  background: #ffffff;
  border: 1px solid #868686;
  color: #868686;
  margin: 4px 121px 0 5px;
  float: right;
  text-align: left !important;
}

.tooltip-qm {
  float: left;
  margin: -2px 0px 3px 4px;
  font-size: 12px;
}

.tooltip-inner {
  max-width: 200px !important;
  height: auto;
  font-size: 12px;
  padding: 10px 15px 10px 20px;
  background: #868686;
  color: #ffffff;
  border: 1px solid #868686;
  text-align: left;
  padding-bottom: 10px;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #868686;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #868686;
}

.tooltipbtn {
  font-weight: 500;
  width: 50px;
  max-width: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.transportSelectDropdown {
  width: 153px !important;
  border: none;
  background: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px;
  line-height: 1.3;
  border: 0;
  border-radius: 5px;
  height: 25px;
  background: url(/static/media/dropdownArrow.42eab290.svg) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 127px;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
}

.transportSelectDropdown > .Dropdown-control > .Dropdown-placeholder {
  color: #838383 !important;
}

.transportSelectDropdown > .Dropdown-control > .is-selected {
  color: #484848 !important;
}

*:focus {
  outline: none;
}

.plantLocationColumnType {
  display: flex;
  /* flex-direction: column; */
}

.bell-icon {
  margin-right: 48px;
  cursor: pointer;
}

.container-fluid-for-mobile-View {
  display: none;
}

/* For inside screen/table */
.container-fluid-for-mobile-screen-View {
  display: none;
}

.display-none {
  display: none;
}

.dashboardSearchDiv {
  display: none;
}

/* .hamburger-icon {
  display: none;
} 

.backdrop {
  display: none;
}

.backdrop {
  display: block;
  z-index: 4;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000096;
  top: 0;
}

*/

.hamburger-icon {
  display: block;
  height: max-content;
  cursor: pointer;
}

.icon-invert {
  filter: invert(1);
}

.toggle {
  transform: translate(-283px, 0px);
  transition: 0.3s linear;
}

@media (max-width: 880px) {

  .sidebar {
    top: 5rem !important;
  }

}


@media screen and (max-width: 480px) {
  body {
    font-size: 13px;
  }

  .mainContent {
    margin-left: 0;
    padding: 0;
  }

  /* .nav-tabs .nav-link {
    font-weight: 600;
  } */

  .icon-invert {
    filter: invert(1);
  }

  .navbar {
    height: 4rem;
    box-shadow: 0px 3px 10px #00000047;
  }

  .hamburger-icon {
    display: block;
    height: max-content;
  }

  .backdrop {
    display: block;
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #00000096;
    top: 0;
  }

  .sidebar {
    z-index: 2;
  }

  .sidebar {
    /* transform: translate(0, 0px); */
    transition: 0.2s linear;
  }

  .toggle {
    transform: translate(-283px, 0px);
    transition: 0.3s linear;
  }

  .dimen {
    width: 95vw;
  }

  .container-fluid-for-desktop-view {
    display: none !important;
  }

  .container-fluid-for-mobile-View {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .container-fluid-for-mobile-View > .header-list-icon {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding-left: 0;
  }

  .container-fluid-for-mobile-screen-View {
    display: initial;
  }

  .bell-icon,
  .profile-icon,
  .location-icon {
    margin-right: 0;
    margin-left: 1.5rem;
    cursor: pointer;
  }

  .location-dropdown {
    position: fixed;
    right: 7rem;
    background-color: #ffffff;
    box-shadow: -1px 0px 10px #00000045;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0.25rem;
    margin-top: 3px;
  }

  .profile-dropdown {
    position: fixed;
    right: 1rem;
    background: #ffffff;
    box-shadow: 0px 0px 6px #00000010;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-top: 3px;
    min-width: 7rem;
  }

  .Dropdown-placeholder {
    color: #4f85c4;
    font-weight: bold;
    margin-top: 5px;
  }

  .username {
    color: #ffa100;
    padding: 0.5rem;
  }

  .signOutButton {
    color: #4f85c4;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  #profile-dropdown hr {
    margin: 0;
  }

  .footerLinkText {
    position: relative;
    font-size: 10px;
    white-space: nowrap;
  }

  .CopyrightTextFooter {
    font-size: 10px;
    color: rgb(153, 153, 153);
  }

  .dashboardSearchDiv {
    display: initial;
    position: relative;
    height: max-content;
  }

  .dashboardSearchInput {
    background: #ffffff !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    width: 100%;
    height: 27px;
    top: 151px;
    right: 50px;
    left: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #00000033 !important;
    border-radius: 4px !important;
    opacity: 1;
    margin-bottom: 10px;
  }

  .dashboardSearchIcon {
    background-color: #ffffff;
    cursor: pointer;
    position: absolute;
    transform: translate(-35px, 5px);
  }
  .dashboard-for-mobile {
    background-color: #f7f7f7;
    position: fixed;
    z-index: 9;
    width: 86%;
    left: 0;
    height: 280px;
    margin-top: -285px;
    margin-left: 2rem;
  }
  .dashboardcontainer {
    padding-left: 9%;
    padding-right: calc(9% - 35px);
    padding-top: 290px;
  }
  .subTitleText {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    letter-spacing: 0;
  }
  .subTitleTextD {
    width: 90%;
    margin-left: 1.1rem;
    justify-content: space-between !important;
  }

  #home_card > .card {
    width: 110px !important;
    height: 110px !important;
    max-width: 110px;
    min-width: 110px !important;
    margin-bottom: 30px !important;
    margin-right: 6%;
    box-shadow: 0px 3px 10px #0000003b;
    justify-content: center;
    border-radius: 6px;
    flex-direction: row;
    font-weight: bold;
  }

  #home_card > .card > .card-body > p {
    font-size: 11px;
  }

  .cardDotHolder {
    height: 26px;
    margin-left: 100px;
    margin-top: 30px;
  }

  .srbFooter {
    position: static;
    padding: 15px 0;
  }

  .footer {
    padding-left: 10px;
  }
}

@media screen and (min-width: 2700px) {
  .subTitleText {
    font-size: 36px;
  }

  .sidebar {
    top: 9%;
  }

  .icon-text {
    font-size: 15px;
  }
}
