.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
}

.ReactTable .rt-tbody .rt-td {
  border: none !important;
  font-size: 14px !important;
  color: #484848;
  letter-spacing: 0;
}

.ReactTable {
  border: none !important;
}

.ReactTable .rt-thead .rt-th {
  border: none !important;
  font-size: 14px !important;
  color: #838383 !important;
  letter-spacing: 0;
}

.rt-table::-webkit-scrollbar,
.sidebar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* border: 1px solid red; */
  border-radius: 5px;
}
.rt-table::-webkit-scrollbar-track,
.sidebar ::-webkit-scrollbar {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
.rt-table::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.ReactTable .rt-thead {
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
}

.rt-tr-group {
  background: #f9fafb;
}
.rt-tr-group:nth-of-type(even) {
  background: #ffffff;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
}
.ReactTable .rt-tr {
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.ReactTable .rt-thead .rt-th:last-child {
  text-align: right;
}
.ReactTable .rt-tbody .rt-td:last-child {
  text-align: right;
}

.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  border-bottom: 0;
}
input[type="text"],
input[type="number"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 15px;
  color: #484848;
  letter-spacing: 0;
}
input[type="text"],
input[type="number"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  padding-left: 1px;
}

/* input[type=text], input[type=number], input[type=password] {
  padding-left: 1px;
  padding-right: 1px;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.table-form-group {
  margin: 0;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr {
  align-items: center !important;
  height: 70px;
  max-height: 70px;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  align-items: center;
}

.disabled-button,
.disabled-button > span {
  color: #9f9f9f;
  background-color: #e0e0e0 !important;
}

.ReactTable .rt-tbody {
  overflow: unset !important;
}
.rt-tr {
  display: flex !important;
  margin: 0 !important;
  align-items: center !important;
}

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="number"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="date"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="time"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.AssignLogisticsPartnerTable,
.AssignTruckTable,
.freCharges,
.logPartner,
.OrderClosureTable,
.liscrap,
.mixscrap,
.elvscrap,
.indscrap,
.SamacharTable,
.volIntable,
.AssignRolesToResourceTable,
.poptable {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 370px !important;
}

.AssignLogisticsPartnerTable .rt-thead.-header,
.freCharges .rt-thead.-header,
.AssignTruckTable .rt-thead.-header,
.logPartner .rt-thead.-header,
.OrderClosureTable .rt-thead.-header,
.liscrap .rt-thead.-header,
.mixscrap .rt-thead.-header,
.elvscrap .rt-thead.-header,
.indscrap .rt-thead.-header,
.SamacharTable .rt-thead.-header,
.AssignRolesToResourceTable .rt-thead.-header,
.volIntable .rt-thead.-header,
.poptable .rt-thead.-header {
  position: sticky !important;
  top: 0 !important;
  background: #ffffff !important;
}

.confrmbtn {
  float: right;
  margin-right: 1%;
  margin-bottom: 1.5%;
  background: #4f85c4;
  color: #ffffff;
  border-radius: 4px;
  border-radius: 4px;
  border: #4f85c4;
}

.btn-text {
  font-family: "MyriadPro-Semibold", Myriad, Liberation Sans, Nimbus Sans L,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0;
}

.btn {
  display: inline-flex;
  width: 164px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 6px;
  margin-right: 21px;
  padding: 0px;
}

.btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.pricing-card-body {
  padding: 0 !important;
}

.react-datepicker__input-container {
  /* width:100px !important; */
  border-bottom: unset !important;
}
.react-datepicker-ignore-onclickoutside {
  width: 85px !important;
  pointer-events: none !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-popper[data-placement^="top"] {
  left: -60px !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  left: -60px !important;
}
.react-datepicker__triangle {
  left: 80px !important;
}

.dateDiv {
  width: 85px;
  height: 38px;
  background-color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AssignTruckPoc {
  margin-left: 16px;
  font-size: 12px;
  color: #838383;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 3%;
}

#selectDropdownStatus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 120px;
  line-height: 2;
  border: 0;
  border-radius: 5px;
  height: 25px;
  background: url("../images/icon/dropdownArrow.svg") no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 175px;
  font-size: 15px;
  color: #000000;
  border: none;
  padding: 0;
  background-color: transparent;
  padding-left: 0px;
}

.dropDownAction {
  border-bottom: none;
  background-position-x: 100px !important;
}
.searchVendorCode {
  width: 150px;
  color: #838383;
  border-color: #838383 !important;
}

.confirm {
  float: right;
  margin-right: 4%;
  margin-top: 5.8%;
  background: #4f85c4;
  border-radius: 4px;
  font-family: "MyriadPro-Semibold", Myriad, Liberation Sans, Nimbus Sans L,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #ffffff;
}

.editsbtn {
  cursor: pointer;
  color: blue;
  background-color: transparent;
  border: none;
}
.editsbtn:focus {
  outline: none;
}
.poc {
  margin-top: 5%;
  margin-left: 2.2%;
  font-size: 12px;
  color: #838383;
  margin-bottom: 3%;
}

.rthfc.-sp .rthfc-th-fixed-left,
.rthfc.-sp .rthfc-td-fixed-left {
  border-right: solid 1px #ccc !important;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.6% !important;
  color: #4f85c4 !important;
  position: sticky !important;
}

.react-time-picker {
  background-color: none !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.react-time-picker--disabled {
  background: none !important;
  background-color: none !important;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.react-time-picker__inputGroup__input {
  border: none !important;
}

.react-time-picker__inputGroup__input1 {
  border-bottom: 1px solid !important;
}
.react-time-picker__clear-button {
  display: none;
}

.react-time-picker__inputGroup__leadingZero {
  display: none;
}

.react-time-picker__inputGroup__amPm option[value=""] {
  font: inherit;
  display: none;
  -moz-appearance: menulist;
}

@media screen and (max-width: 480px) {
  .btn-text {
    font-size: 12px;
  }
}
